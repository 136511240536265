<template>
  <b-card body-class="pa-0">
    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            first-number
            last-number
          />
        </div>
      </b-col>
    </b-row>

    <b-table
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      :fields="instances_headers"
      :items="instances"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
    >
      <template #cell(actions)="{ item }">
        <a href="#" class="btn btn-icon btn-light btn-sm mx-3" @click="selectInstanceClicked(item.id)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/assets/svg/Write.svg" />
          </span>
        </a>

        <a class="btn btn-icon btn-light btn-sm mx-3" @click="deleteInstanceClicked(item.id)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/assets/svg/Trash.svg" />
          </span>
        </a>
      </template>
    </b-table>

  </b-card>
</template>

<script>
import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'InstancesTable',
  components: {
    Confirm
  },
  mixins: [toasts],
  computed: {},
  props: ['instances'],
  emits: ['onSelectInstance', 'onDeleteInstance'],
  mounted() {},
  data() {
    return {
      instances_headers: [
        {
          label: 'Domän',
          sortable: true,
          key: 'domain',
        },
        {
          label: 'Namn',
          sortable: true,
          key: 'name',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          label: 'Kortnamn',
          sortable: true,
          key: 'shortname',
          tdClass: 'td-overflow td-short5',
          thClass: 'td-overflow td-short5'
        },
        {
          label: 'Uptid',
          sortable: true,
          key: 'last_polled_at',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: 'text-right',
          thClass: 'text-right',
        }
      ],
      total_rows: 0,
      current_page: 1,
      per_page: 100,
      search: null
    };
  },
  watch: {
    instances() {
      this.total_rows = this.instances.length;
    }
  },
  methods: {
    selectInstanceClicked(id) {
      this.$emit('onSelectInstance', id);
    },
    deleteInstanceClicked(id) {
      this.$emit('onDeleteInstance', id);
    },

  }
};
</script>

<style scoped lang="scss">



:deep .td-short {
  width: 32px;
}

:deep .td-short2 {
  width: 56px;
}

:deep .td-short3 {
  width: 76px;
}

:deep .td-short4 {
  width: 96px;
}

:deep .td-short5 {
  width: 116px;
}

:deep td {
  vertical-align: middle !important;
}
</style>
