<template>
  <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100">
    <b-button
      variant="primary"
      :type="type"
      :disabled="disabled || spinning"
      @click.prevent="on_click"
    >
      <b-spinner v-if="spinning" small type="grow"></b-spinner>
      {{ text }}
    </b-button>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';

import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'RightSaveButton',
  components: {},
  mixins: [ toasts ],
  props: {
    text: { type: String },
    disabled: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
  },
  emits: ['clicked'],
  computed: {
    ...mapGetters([])
  },
  data() {
    return {
      spinning: false
    };
  },
  watch: {},
  mounted() {},
  methods: {
    stop(delay = 500) {
      const that = this;

      setTimeout(()=>{
        that.spinning = false;
      }, delay);
    },

    on_click() {
      this.spinning = true;

      this.$emit('clicked');
    }
  }
};
</script>

<style scoped>

</style>
