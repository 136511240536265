<template>
  <div class="px-5" id="page-instance-editor">
    
    <b-card
      title="Editera instans"
      class="mb-2 edit-instance-container"
      body-class="pa-0"
      ref="edit-instance"
      id="edit-instance"
      hide-footer
      v-if="form"
    >
      <div>
        <b-form class="mt-8" v-on:submit.prevent="saveInstance">
          <b-form-group id="input-group-id" label="ID" label-for="input-id">
            <b-form-input
              id="input-id"
              v-model="form.id"
              type="text"
              required
              disabled
            ></b-form-input>
          </b-form-group>


          <b-form-group
            id="name-group"
            label="Namn"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="form.name"
              placeholder="Namn"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="url-group"
            label="Domän"
            label-for="domain"
          >
            <b-form-input
              id="domain"
              v-model="form.domain"
              placeholder="xyz.memlist.se"
            ></b-form-input>
          </b-form-group>

          <p>{{ $t('COMMON.SERVICE_DOMAIN_INFO') }}</p>

          <b-form-group
            id="instance_id-group"
            label="Instans ID"
            label-for="instance_id"
          >
            <b-form-input
              id="instance_id"
              v-model="form.instance_id"
              placeholder="LUF, SMFS, etc"
            ></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('COMMON.PERIOD_TYPE')" label-for="period_options">
            <b-form-select 
              id="period_options" 
              :options="period_options" 
              v-model="form.period_type" />
          </b-form-group>

          <b-form-group
            id="token-group"
            label="Token"
            label-for="token"
          >
            <b-form-input
              id="token"
              v-model="form.token"
              placeholder="Token"
            ></b-form-input>
          </b-form-group>

          <div v-if="form.service === 'CREATING'" class="d-flex justify-content-center mb-8 mt-8">
            <b-spinner label="Loading..."></b-spinner>
          </div>

          <div v-else-if="form.service === 'CREATED'">
            <p style="color: green;">{{ $t('COMMON.SERVICE_CREATED') }}</p>
          </div>

          <b-button v-if="form.id && form.service === 'NONE'" variant="primary" @click="create_service">{{ $t('COMMON.CREATE_SERVICE') }}</b-button>

          <RightSaveButton
            ref="save-button"
            :text="$t('COMMON.SAVE')"
            @clicked="submit_clicked()"
          />
          
        </b-form>

      </div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';


export default {
  name: "instances-editor",

  components: {
    RightSaveButton
  },
  props: ['instance_id'],
  mixins: [ toasts ],
  emits: ['onInstanceCreated', 'onInstanceUpdated'],
  watch: {
    instance_id() {
      if (this.instance_id) {
        this.get_instance(this.instance_id);
      }
      else {
        this.form = {};
      }
    }
  },
  computed:{
    ...mapGetters(['isTHS']),
    period_options() {
      return [
        { text: this.$t('COMMON.PERIOD_TYPES.INFINITE'), value: 'INFINITE' },
        { text: this.$t('COMMON.PERIOD_TYPES.PERIOD'), value: 'PERIOD' }
      ];
    }
  },
  mounted() {
    if (this.instance_id) {
      this.get_instance(this.instance_id);
    }
    else {
      this.form = {};
    }
  },
  methods: {
    
    async get_instance(id) {
      try {
        const res = await axios.get(`/ths/instance/${id}`);
        
        if (res.status === 200) {
          this.form = res.data;
        }
        else {
          this.toastr('success',this.$t('COMMON.ERROR'),'Gick inte att hämta instans');
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger',this.$t('COMMON.ERROR'),'Kunde inte hämta instans');
      }
    },

    submit_clicked() {
      this.saveInstance();
    },

    async saveInstance() {
      if (this.form.id) {
        await this.put_instance();
      }
      else {
        await this.post_instance();
      }

      this.$refs['save-button'].stop();
    },


    async post_instance() {
      try {
        const res = await axios.post(`/ths/instance`, this.form);

        if (res.status === 201) {
          this.toastr('success',this.$t('COMMON.OK'),'Instans skapad');
          this.form = res.data;
          this.$emit('onInstanceCreated', this.form);
        }
        else {
          this.toastr('danger',this.$t('COMMON.ERROR'),'Gick inte att skapa');
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger',this.$t('COMMON.ERROR'),'Kunde inte skapa instans');
      }
    
    },

    async create_service() {
      try {
        const res = await axios.post(`/ths/instance/${this.form.id}/service`, this.form.id);

        if (res.status === 201) {
          this.toastr('success',this.$t('COMMON.OK'), 'Service skapad');
          this.form = res.data;
        }
        else {
          this.toastr('danger',this.$t('COMMON.ERROR'),'Gick inte att service');
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger',this.$t('COMMON.ERROR'),'Kunde inte skapa service');
      }
    
    },

    async put_instance() {
      try {
        const res = await axios.put(`/ths/instance/${this.form.id}`, this.form)

        if (res.status === 204) {
          this.toastr('success',this.$t('COMMON.OK'),'Instans uppdaterad');

          this.$emit('onInstanceUpdated', this.form);
        }
        else {
          this.toastr('success',this.$t('COMMON.ERROR'),'Gick inte att uppdatera');
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger',this.$t('COMMON.ERROR'),'Kunde inte uppdatera instans');
      }
    },

  },
  data() {
    return {
      form: {}
    };
  }
};
</script>
